import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  MultiversxProvider,
  MultiversxWalletDestination,
} from '../constants/common'
import removeQueryParams from '../lib/utils/removeQueryParams'
import ConfirmUserWalletModal from '../components/multiversx/linkWallet/modals/ConfirmUserWalletModal'
import axios, { AxiosError } from 'axios'
import userService from '../services/user.service'
import { diplayAlert } from '../lib/utils/diplayAlert'
import {
  setWallet,
  setUserPayoutMethods,
  useDispatch,
  useSelector,
  getMemoizedUserPayoutMethods,
} from '../store'
import SuccessUserWalletModal from '../components/multiversx/linkWallet/modals/SuccessUserWalletModal'
import { PayoutCryptoCurrency } from '../types/Payout'
import { NativeAuthClient } from '@multiversx/sdk-native-auth-client'
import {
  getCurrentPathWithoutQueryParams,
  getQueryParams,
  removeSpecificQueryParams,
} from '../lib/utils/queryParams'

enum RedirectStatus {
  SUCCESS = 'success',
  CANCELLED = 'cancelled',
}

export enum AuthType {
  CUSTOM = 'custom',
  NATIVE = 'native',
}

const MultiversxInterceptor = ({ children }: { children: any }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const userPayoutMethods = useSelector(getMemoizedUserPayoutMethods)
  const [modalConfirm, setModalConfirm] = useState<boolean>(false)
  const [modalSuccess, setModalSuccess] = useState<boolean>(false)

  const linkMethod = router.query.linkMethod as MultiversxProvider
  const destination = router.query.destination as MultiversxWalletDestination
  const address = router.query.address as string | undefined
  const signature = router.query.signature as string | undefined
  const authToken = router.query.authToken as string | undefined
  const status = router.query.status as RedirectStatus | undefined
  const authType = router.query.authType as AuthType | undefined

  const confirmLinkWallet = async (): Promise<void> => {
    if (
      address &&
      signature &&
      status &&
      status !== RedirectStatus.CANCELLED &&
      authToken
    ) {
      switch (destination) {
        case MultiversxWalletDestination.ADD_WALLET:
          try {
            if (authType === AuthType.NATIVE) {
              const nativeAuthClient = new NativeAuthClient({
                expirySeconds: 300,
                apiUrl: process.env.NEXT_PUBLIC_MX_API_URL,
              })
              const accessToken = nativeAuthClient.getToken(
                address,
                authToken,
                signature,
              )
              const newWallet = await userService.createWalletV2(
                accessToken,
                'MultiversX custodial wallet',
              )
              dispatch(setWallet(newWallet))
            } else {
              const newWallet = await userService.createWallet(
                address,
                signature,
                authToken,
                'MultiversX custodial wallet',
              )
              dispatch(setWallet(newWallet))
            }
            setModalSuccess(true)
          } catch (err: any | AxiosError) {
            if (
              axios.isAxiosError(err) &&
              err.response &&
              err.response.data.message ===
                'Wallet already used by another user'
            ) {
              diplayAlert(
                'Cette adresse est déjà utilisé par un compte Shelters.',
              ) // todo: translation
            }
          }
          break
        case MultiversxWalletDestination.ADD_PAYOUT_WALLET:
          try {
            if (authType === AuthType.NATIVE) {
              const nativeAuthClient = new NativeAuthClient({
                expirySeconds: 300,
                apiUrl: process.env.NEXT_PUBLIC_MX_API_URL,
              })
              const accessToken = nativeAuthClient.getToken(
                address,
                authToken,
                signature,
              )
              const newUserPayoutMethod =
                await userService.createPayoutMethodMultiversxV2(
                  accessToken,
                  PayoutCryptoCurrency.USDC,
                )
              dispatch(
                setUserPayoutMethods([
                  ...userPayoutMethods,
                  newUserPayoutMethod,
                ]),
              )
            } else {
              const newUserPayoutMethod =
                await userService.createPayoutMethodMultiversx(
                  address,
                  signature,
                  authToken,
                  PayoutCryptoCurrency.USDC,
                )
              dispatch(
                setUserPayoutMethods([
                  ...userPayoutMethods,
                  newUserPayoutMethod,
                ]),
              )
            }

            setModalSuccess(true)
          } catch (err: any | AxiosError) {
            if (
              axios.isAxiosError(err) &&
              err.response &&
              err.response.data.message === 'Wallet already in use'
            ) {
              diplayAlert('Cette adresse est déjà lié à votre compte Shelters.') // todo: translation
            } else {
              diplayAlert('Une erreur est survenue') // todo: translation
            }
          }
          break
        default:
          diplayAlert(
            'Une erreur est survenue lors de la liaison avec votre compte Shelters et votre wallet MultiversX',
          )
      }

      removeQueryParams(router)
    }
  }

  const handleOnClose = async () => {
    setModalConfirm(false)

    const currentPath = getCurrentPathWithoutQueryParams(router.asPath)
    const existingQueryParams = getQueryParams(router.asPath)
    const keysToRemove = [
      'linkMethod',
      'destination',
      'address',
      'signature',
      'authToken',
      'status',
      'authType',
    ]
    const cleanedQueryParams = removeSpecificQueryParams(
      existingQueryParams,
      keysToRemove,
    )

    await router.replace({
      pathname: currentPath,
      query: cleanedQueryParams,
    })
  }

  useEffect(() => {
    if (status === RedirectStatus.CANCELLED) {
      diplayAlert('Connexion au web wallet annulé')
      removeQueryParams(router)
    }

    if (linkMethod && destination && address) {
      setModalConfirm(true)
    }
  }, [router.query])

  return (
    <>
      <ConfirmUserWalletModal
        display={modalConfirm}
        address={address as string}
        onConfirm={async () => {
          setModalConfirm(false)
          await confirmLinkWallet()
        }}
        onClose={handleOnClose}
      />
      <SuccessUserWalletModal
        display={modalSuccess}
        onClose={() => setModalSuccess(false)}
      />
      {children}
    </>
  )
}

export default MultiversxInterceptor
