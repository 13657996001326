import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { WalletConnectV2Provider } from '@multiversx/sdk-wallet-connect-provider/out'
import { ExtensionProvider } from '@multiversx/sdk-extension-provider/out'
import { HWProvider } from '@multiversx/sdk-hw-provider/out'
import {
  WalletProvider,
  WALLET_PROVIDER_DEVNET,
  WALLET_PROVIDER_MAINNET,
} from '@multiversx/sdk-web-wallet-provider/out'

const projectId = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID
const relayUrl = 'wss://relay.walletconnect.com'
const chainId = process.env.NEXT_PUBLIC_CHAIN_ID

export interface MultiversxContextProps {
  defiWalletProvider?: ExtensionProvider
  webWalletProvider?: WalletProvider
  walletConnectProvider?: WalletConnectV2Provider
  ledgerProvider?: HWProvider
  setWalletConnectCB: Dispatch<SetStateAction<CbType>>
}

const multiversxContext = createContext<MultiversxContextProps>(
  undefined as any,
)

interface CbType {
  onClientLogin: () => void
  onClientLogout: () => void
  onClientEvent: (event: string) => void
}

const MultiversxProvider = ({ children }: { children: any }) => {
  const [walletConnectCB, setWalletConnectCB] = useState<CbType>({
    onClientLogin: () => {},
    onClientLogout: () => {},
    onClientEvent: () => {},
  })
  const callbacks = {
    onClientLogin: async function () {
      walletConnectCB.onClientLogin()
    },
    onClientLogout: async function () {
      walletConnectCB.onClientLogout()
    },
    onClientEvent: async function (event: any) {
      walletConnectCB.onClientEvent(event)
    },
  }
  const [defiWalletProvider, setDefiWalletProvider] =
    useState<ExtensionProvider>(ExtensionProvider.getInstance())
  const [webWalletProvider, setWebWalletProvider] = useState<WalletProvider>()
  const [walletConnectProvider, setWalletConnectProvider] =
    useState<WalletConnectV2Provider>(
      new WalletConnectV2Provider(callbacks, chainId, relayUrl, projectId, {
        logger: 'debug',
      }),
    )
  const [ledgerProvider, setLedgerProvider] = useState<HWProvider>(
    new HWProvider(),
  )

  useEffect(() => {
    let provider: WalletProvider
    if (process.env.NEXT_PUBLIC_TARGET_ENV === 'production') {
      provider = new WalletProvider(WALLET_PROVIDER_MAINNET)
    } else {
      provider = new WalletProvider(WALLET_PROVIDER_DEVNET)
    }
    setWebWalletProvider(provider)
  }, [])

  return (
    <multiversxContext.Provider
      value={{
        defiWalletProvider,
        walletConnectProvider,
        webWalletProvider,
        ledgerProvider,
        setWalletConnectCB,
      }}
    >
      {children}
    </multiversxContext.Provider>
  )
}

export default MultiversxProvider

export const useMultiversx = () => useContext(multiversxContext)
