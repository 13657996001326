import React from 'react'
import { XMarkIcon } from '../../../icons/XMarkIcon'
import Button, { buttonHierarchy, buttonShape } from '../../../Button'
import Card from '../../../Card'
import { useTranslation } from 'next-i18next'

type Props = {
  display: boolean
  address: string
  onConfirm: Function
  onClose: Function
}

export default function ConfirmUserWalletModal({
  display,
  address,
  onConfirm,
  onClose,
}: Props) {
  const { t } = useTranslation('common')

  return (
    <>
      {display && (
        <div className="fixed inset-0 z-10 overflow-y-auto bg-neutralGray/60 transition-opacity backdrop:blur-md">
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Card className="sm:w-lg border border-neutralGray/50 bg-neutralWhite p-3">
                <div className="absolute right-4 top-4 cursor-pointer text-neutralBlack">
                  <button onClick={() => onClose()}>
                    <XMarkIcon />
                  </button>
                </div>
                <div className=" px-4 py-8">
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="mb-3 text-center text-2xl font-bold text-neutralBlack">
                      {t('mvxConnect.confirmLink.title')}
                    </h1>
                    <p className="text-center font-light text-neutralBlack">
                      {t('mvxConnect.confirmLink.subtitle')}
                    </p>

                    <img
                      src="/img/wallet.png"
                      alt=""
                      height={250}
                      width={250}
                    />

                    <p className="my-3 mb-3 font-normal text-neutralBlack">
                      {t('mvxConnect.confirmLink.verifyAddress')}
                    </p>
                    <div className="flex w-full flex-col">
                      <input
                        type="text"
                        className="text-input-xs mb-3 w-full text-center"
                        disabled={false}
                        value={address}
                        readOnly
                      />
                    </div>

                    <div className="flex w-full items-center">
                      <Button
                        hierarchy={buttonHierarchy.tertiary}
                        shape={buttonShape.rectangle}
                        className="mx-auto px-1.5 py-2"
                        onClick={() => onConfirm()}
                      >
                        {t('mvxConnect.confirmLink.confirm')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
