import React from 'react'
import { XMarkIcon } from '../../../icons/XMarkIcon'
import Card from '../../../Card'
import Button, { buttonHierarchy, buttonShape } from '../../../Button'
import { useTranslation } from 'next-i18next'

type Props = {
  display: boolean
  onClose: Function
}

export default function SuccessUserWalletModal({ display, onClose }: Props) {
  const { t } = useTranslation('common')

  return (
    <>
      {display && (
        <div className="fixed inset-0 z-10 overflow-y-auto bg-neutralGray/60 transition-opacity backdrop:blur-md">
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Card className="sm:w-lg border border-neutralGray/50 bg-neutralWhite p-3">
                <div className="absolute right-4 top-4 cursor-pointer text-neutralBlack">
                  <button onClick={() => onClose()}>
                    <XMarkIcon />
                  </button>
                </div>
                <div className=" px-4 py-8">
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="mb-3 text-center text-2xl font-bold text-neutralBlack">
                      {t('mvxConnect.successLink.title')}
                    </h1>
                    <p className="text-center font-light text-neutralBlack">
                      {t('mvxConnect.successLink.subtitle')}
                    </p>

                    <img
                      src="/img/check.png"
                      alt="check icon"
                      height={100}
                      width={100}
                      className="mb-5 mt-3"
                    />

                    <Button
                      hierarchy={buttonHierarchy.primary}
                      shape={buttonShape.rectangle}
                      className="mx-auto px-1.5 py-2"
                      onClick={() => onClose()}
                    >
                      {t('mvxConnect.successLink.close')}
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
