import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { Property } from '../../../types/Property/Property'

const initialState: PropertySliceState = {
  properties: [],
  propertiesLoaded: false,
  currentProperty: null,
}

export const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setProperties: (state, action: PayloadAction<Property[]>) => {
      state.properties = action.payload
      state.propertiesLoaded = true
    },
    setCurrentProperty: (state, action: PayloadAction<Property>) => {
      state.currentProperty = action.payload
    },
  },
})

export const { setProperties, setCurrentProperty } = propertySlice.actions

/* Types */
export interface PropertySliceState {
  properties: Property[]
  propertiesLoaded: boolean
  currentProperty: Property | null
}
