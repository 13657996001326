import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import constants from '../config/constants'
import { i18n } from 'next-i18next'

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASEURL,
  withCredentials: true,
})

instance.interceptors.request.use(
  (config) => {
    // Modify the request here
    config.headers['Accept-Language'] = i18n?.language
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    // Modify the response here
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem(constants.IS_LOGGED_IN)
      localStorage.removeItem(constants.AUTH_TOKEN)
      window.location.reload()
    }
    if (error.response?.status?.toString().charAt(0) === '5') {
      Bugsnag.notify(error)
    }
    return Promise.reject(error)
  },
)

export default instance
