import React, { CSSProperties } from 'react'
import { classNames } from '../lib/utils/classNames'

type CardProps = {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}

export default function Card({ children, className, style }: CardProps) {
  return (
    <div
      className={classNames(
        'relative rounded-2xl shadow-sm backdrop-blur-lg',
        className || '',
      )}
      style={style}
    >
      {children}
    </div>
  )
}
