import { useState, createContext, useContext } from 'react'
import NotificationConsumer from '../components/NotificationConsumer'
import { v4 as uuid } from 'uuid'

interface NotificationContextProps {
  notifications: Notification[]
  success: (title: string, text: string, duration: number) => void
  error: (title: string, text: string, duration: number) => void
  clear: () => void
  remove: (id: string) => void
}

const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  success: (title: string, text: string, duration: number) => {},
  error: (title: string, text: string, duration: number) => {},
  clear: () => {},
  remove: (id: string) => {},
})

export interface Notification {
  id: string
  type: 'success' | 'error'
  title: string
  text: string
}

const NotificationProvider = (props: any) => {
  const [notifications, setNotifications] = useState<Notification[]>([])

  const success = (title: string, text: string, duration: number) => {
    const id = uuid()
    setNotifications((_notifications: Notification[]) => [
      ..._notifications,
      { id, type: 'success', title, text },
    ])
    setTimeout(() => remove(id), duration)
  }

  const error = (title: string, text: string, duration: number) => {
    const id = uuid()
    setNotifications((_notifications: Notification[]) => [
      ..._notifications,
      { id, type: 'error', title, text },
    ])
    setTimeout(() => remove(id), duration)
  }

  const remove = (id: string) => {
    setNotifications((_notifications: Notification[]) =>
      _notifications.filter((n) => n.id !== id),
    )
  }

  const clear = () => {
    setNotifications([])
  }

  return (
    <NotificationContext.Provider
      value={{
        success,
        error,
        clear,
        remove,
        notifications,
      }}
    >
      {props.children}
      <NotificationConsumer notifications={notifications} />
    </NotificationContext.Provider>
  )
}

export { NotificationProvider }

export const useNotification = () => useContext(NotificationContext)
