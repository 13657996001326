import { selectCurrency, setCurrency, useDispatch, useSelector } from '../store'
import { CurrencyEnum } from '../types/Currency'

const useCurrency = () => {
  const currency = useSelector(selectCurrency)
  const dispatch = useDispatch()

  const _setCurrency = (currency: CurrencyEnum) => {
    dispatch(setCurrency(currency))
  }

  return { currency, setCurrency: _setCurrency }
}

export default useCurrency
