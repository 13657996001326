import { NextRouter } from 'next/router'

const removeQueryParams = (router: NextRouter, params: string[] = []) => {
  const url = new URL(`${process.env.NEXT_PUBLIC_BASEURL}${router.asPath}`)

  if (params.length) {
    params.forEach((param) => {
      url.searchParams.delete(param)
    })
  } else {
    url.search = ''
  }
  router.replace(url, undefined, { shallow: true })
}

export default removeQueryParams
