const routes = {
  index: '/',
  login: '/login/',
  register: '/register/',
  forgotPassword: '/forgot-password/',
  logout: '/logout/',
  registerSuccess: '/register-success/',
  dashboard: '/dashboard/',
  account: {
    index: '/account/',
    profile: '/account/profile/',
    payouts: '/account/payouts/',
    wallet: '/account/wallet/',
    security: '/account/security/',
    purchaseHistory: '/account/purchase-history/',
  },
  marketplace: '/marketplace/',
  properties: '/properties/',
  property: '/properties/[propertyId]/',
  checkoutQueue: '/properties/[propertyId]/checkout/queue',
  checkoutOverview: '/properties/[propertyId]/checkout/overview',
  checkoutTimeout: '/properties/[propertyId]/checkout/timeout',
  checkoutComplete: '/properties/[propertyId]/checkout/complete/',
  checkout: '/properties/[propertyId]/checkout/',
  order: '/orders/[orderId]/',
}

export default routes
