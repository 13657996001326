const path = require('path')

/** @type import("next-i18next").UserConfig */
module.exports = {
  i18n: {
    defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
    locales: ['en', 'fr'],
    localeDetection: false,
  },
  localePath: path.resolve('./public/locales'),
}
