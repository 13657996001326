import React, { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { HeroIcons } from '../lib/utils/heroIcons'
import {
  Notification,
  useNotification,
} from '../providers/NotificationProvider'

const NotificationConsumer = ({ notifications }: { notifications: any }) => {
  const { remove } = useNotification()

  const renderIcon = (notification: Notification) => {
    if (notification.type === 'error') {
      return (
        <HeroIcons.XCircleIcon
          className={`h-6 w-6 text-red-600`}
          aria-hidden="true"
        />
      )
    }
    if (notification.type === 'success') {
      return (
        <HeroIcons.CheckCircleIcon
          className={`h-6 w-6 text-green-600`}
          aria-hidden="true"
        />
      )
    }
    return (
      <HeroIcons.CheckCircleIcon
        className={`h-6 w-6 text-green-600`}
        aria-hidden="true"
      />
    )
  }

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex flex-col items-end px-4 py-6 sm:items-start sm:px-8 sm:py-3"
      >
        {notifications.map((notification: any) => {
          return (
            <div
              className="mb-3 flex w-full flex-col items-center space-y-4 sm:items-end"
              key={notification.id}
            >
              <Transition
                as={Fragment}
                show={true}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {renderIcon(notification)}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {notification.title}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {notification.text}
                        </p>
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            remove(notification.id)
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <HeroIcons.XMarkIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default NotificationConsumer
