import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  BillingAddress,
  NextPayout,
  SheltersNft,
  User,
  UserPreferences,
  UserWallet,
  PayoutHistory,
} from '../../../types/User'
import { PayoutMethod, UserPayoutMethod } from '../../../types/Payout'
import { UserSession } from '../../../types/UserSession'

const initialState: UserSliceState = {
  user: null,
  userPreferences: null,
  wallet: null,
  billingAddresses: [],
  userPayoutMethods: [],
  payoutMethods: [],
  nfts: [],
  nftsLoaded: false,
  yields: [],
  payoutsHistory: [],
  userSessions: [],
  userCurrentSession: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setUserPreferences: (state, action: PayloadAction<UserPreferences>) => {
      state.userPreferences = action.payload
    },
    setWallet: (state, action: PayloadAction<UserWallet | null>) => {
      state.wallet = action.payload
    },
    setBillingAddresses: (state, action: PayloadAction<BillingAddress[]>) => {
      state.billingAddresses = action.payload
    },
    setPayoutMethods: (state, action: PayloadAction<PayoutMethod[]>) => {
      state.payoutMethods = action.payload
    },
    setUserPayoutMethods: (
      state,
      action: PayloadAction<UserPayoutMethod[]>,
    ) => {
      state.userPayoutMethods = action.payload
    },
    setNfts: (state, action: PayloadAction<SheltersNft[]>) => {
      state.nfts = action.payload
      state.nftsLoaded = true
    },
    setYields: (state, action: PayloadAction<NextPayout[]>) => {
      state.yields = action.payload
    },
    setPayoutsHistory: (state, action: PayloadAction<PayoutHistory[]>) => {
      state.payoutsHistory = action.payload
    },
    setUserSessions: (state, action: PayloadAction<UserSession[]>) => {
      state.userSessions = action.payload
    },
    setUserCurrentSession: (state, action: PayloadAction<UserSession>) => {
      state.userCurrentSession = action.payload
    },
  },
})

export const {
  setUser,
  setUserPreferences,
  setWallet,
  setBillingAddresses,
  setUserPayoutMethods,
  setPayoutMethods,
  setNfts,
  setYields,
  setPayoutsHistory,
  setUserSessions,
  setUserCurrentSession,
} = userSlice.actions

/* Types */
export interface UserSliceState {
  user: User | null
  userPreferences: UserPreferences | null
  wallet: UserWallet | null
  billingAddresses: BillingAddress[]
  userPayoutMethods: UserPayoutMethod[]
  payoutMethods: PayoutMethod[]
  nfts: SheltersNft[]
  nftsLoaded: boolean
  yields: NextPayout[]
  payoutsHistory: PayoutHistory[]
  userSessions: UserSession[]
  userCurrentSession: UserSession | null
}
