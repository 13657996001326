import { useTranslation } from 'next-i18next'
import i18nextConfig from '../../next-i18next.config'
import { NextRouter, useRouter } from 'next/router'

const useLocale = () => {
  const router: NextRouter = useRouter()
  const { i18n } = useTranslation()

  const locale = router.locale || i18nextConfig.i18n.defaultLocale

  const setLocale = async (newLocale: string) => {
    await i18n.changeLanguage(newLocale)
  }

  return {
    locale,
    setLocale,
  }
}

export default useLocale
