export enum MultiversxProvider {
  WEB_WALLET = 'web_wallet',
  DEFI_WALLET = 'defi_wallet',
  XPORTAL = 'xportal',
  LEDGER = 'ledger',
}

export enum MultiversxWalletDestination {
  ADD_WALLET = 'add_wallet',
  ADD_PAYOUT_WALLET = 'add_payout_wallet',
}

export const MAX_PURCHASABLE_FRACTIONS: number = 1000
