import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out'
import axios, { AxiosInstance } from 'axios'
import { MultiversxNft } from '../types/MultiversX/Nft'

export const apiNetworkProvider = new ApiNetworkProvider(
  process.env.NEXT_PUBLIC_MX_API_URL as string,
)

export const apiMultiversx: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MX_API_URL,
})

const multiversxService = {
  async getCollectionSfts(
    nftCollectionId: string,
    nftNonce: string,
  ): Promise<MultiversxNft[]> {
    const config = {
      params: {
        withSupply: true,
        identifiers: `${nftCollectionId}-${nftNonce}`,
      },
    }
    const { data } = await apiMultiversx.get(
      `/collections/${nftCollectionId}/nfts`,
      config,
    )
    return data
  },
}

export default multiversxService
