export const getCurrentPathWithoutQueryParams = (path: string) => {
  return path.split('?')[0] // Get the pathname without query params
}

export const getQueryParams = (path: string) => {
  const queryString = path.includes('?') ? path.split('?')[1] : null
  if (!queryString) return {}

  return queryString
    .split('&')
    .reduce((acc: { [key: string]: string }, current: string) => {
      const [key, value] = current.split('=')
      acc[key] = value
      return acc
    }, {})
}

export const removeSpecificQueryParams = (
  queryParams: { [key: string]: string },
  keysToRemove: string[],
) => {
  const filteredQueryParams = { ...queryParams }
  keysToRemove.forEach((key) => delete filteredQueryParams[key]) // Remove the specified keys
  return filteredQueryParams
}
