import { useEffect } from 'react'
import { useRouter } from 'next/router'
import api from '../services/api'
import { useAuth } from './AuthProvider'
import routes from '../config/routes'

const AxiosInterceptor = ({ children }: { children: any }) => {
  const router = useRouter()
  const { logout } = useAuth()

  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response
    }

    const errInterceptor = (error: any) => {
      if (error?.response?.status === 401) {
        logout()
        router.replace(routes.login)
      }

      return Promise.reject(error)
    }

    const interceptor = api.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    )

    return () => api.interceptors.response.eject(interceptor)
  }, [router, logout])

  return children
}

export default AxiosInterceptor
