import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { CurrencyEnum } from '../../../types/Currency'

const initialState: AppSettingsSliceState = {
  currency: CurrencyEnum.EUR,
}

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<CurrencyEnum>) => {
      state.currency = action.payload
    },
  },
})

export const { setCurrency } = appSettingsSlice.actions

/* Types */
export interface AppSettingsSliceState {
  currency: CurrencyEnum
}
