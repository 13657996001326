import type { ReduxState } from '../../store'
import { Property } from '../../../types/Property/Property'
import { createSelector } from '@reduxjs/toolkit'
import { getProperties } from '../propertySlice'

export const getUser = (state: ReduxState) => state.user.user

export const getUserSessions = (state: ReduxState) => state.user.userSessions
export const getUserCurrentSession = (state: ReduxState) =>
  state.user.userCurrentSession

export const getUserPreferences = (state: ReduxState) =>
  state.user.userPreferences

export const getWallet = (state: ReduxState) => state.user.wallet

export const getBillingAddresses = (state: ReduxState) =>
  state.user.billingAddresses

const getUserPayoutMethods = (state: ReduxState) => state.user.userPayoutMethods

export const getPayoutMethods = (state: ReduxState) => state.user.payoutMethods

const getNfts = (state: ReduxState) => state.user.nfts

export const getNft = (nftIdentifier: string) => (state: ReduxState) => {
  return state.user.nfts.filter((nft) => nft.identifier === nftIdentifier)[0]
}

export const getNftLoaded = (state: ReduxState) => state.user.nftsLoaded

export const getNftYield = (nftIdentifier: string) => (state: ReduxState) => {
  const yields = state.user.yields

  return yields.find(
    (nft) => nft.nftCollectionId + '-' + nft.nftNonce == nftIdentifier,
  )
}

export const getPayoutsHistory = (state: ReduxState) => {
  return state.user.payoutsHistory
}

export const getFractionCount = (state: ReduxState) => {
  return state.user.nfts
    .map((nft) => nft.balance)
    .reduce((acc, current) => acc + current, 0)
}

export const getTotalPortfolioValue = (state: ReduxState) => {
  const nfts = state.user.nfts
  const userProperties = state.property.properties.filter((property) =>
    nfts.some(
      (nft) =>
        nft.identifier === property.nftCollectionId + '-' + property.nftNonce,
    ),
  )

  return userProperties.reduce((sum, property) => {
    const userNfts = nfts.find(
      (nft) =>
        nft.identifier === property.nftCollectionId + '-' + property.nftNonce,
    )
    if (userNfts) {
      return sum + userNfts.balance * property.fractionCurrentPrice
    }
    return sum
  }, 0)
}

export const getRentalYieldPerYear = (state: ReduxState) => {
  const yields = state.user.yields

  return yields.reduce((acc, currentYield) => {
    return acc + currentYield.nextYield.yearlyYields
  }, 0)
}

export const getRentalYieldPerMonth = (state: ReduxState) => {
  const yields = state.user.yields

  return yields.reduce((acc, currentYield) => {
    return acc + currentYield.nextYield.monthlyYields
  }, 0)
}

export const getCapitalGainPerYear = (state: ReduxState) => {
  const yields = state.user.yields

  return yields.reduce((acc, currentYield) => {
    return acc + currentYield.nextYield.yearlyCapitalGain
  }, 0)
}

export const getMemoizedProperties = createSelector(
  [getProperties, getNfts],
  (properties, nfts) => {
    if (properties && nfts) {
      const identifiers = nfts.map((nft) => nft.identifier)
      return properties.filter((property) =>
        identifiers.includes(
          property.nftCollectionId + '-' + property.nftNonce,
        ),
      )
    }
    return []
  },
)

export const getMemoizedPropertiesWithNft = createSelector(
  [getProperties, getNfts],
  (_properties, nfts) => {
    if (_properties && nfts) {
      const identifiers = nfts.map((nft) => nft.identifier)
      const properties: Property[] = _properties.filter((property) =>
        identifiers.includes(
          property.nftCollectionId + '-' + property.nftNonce,
        ),
      )

      return properties.map((property: Property) => {
        const nft = nfts.filter(
          (nft) =>
            nft.identifier ===
            property.nftCollectionId + '-' + property.nftNonce,
        )[0]
        return { ...property, nft }
      })
    }
    return []
  },
)

export const getMemoizedUserPayoutMethods = createSelector(
  [getUserPayoutMethods],
  (userPayoutMethods) => {
    if (userPayoutMethods) {
      return [...userPayoutMethods].sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      )
    }
    return []
  },
)
