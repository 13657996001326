import { PayoutHistory } from './User'

export interface getUserPayoutMethodsResponse {
  userPayoutMethods: UserPayoutMethod[]
  pagination: any
}

export interface ResponseGetPayoutsHistory {
  payoutsHistory: PayoutHistory[]
}

export interface UserPayoutMethod {
  id: string
  userId: string
  payoutMethod: PayoutMethod
  userPayoutMethodMultiversx: UserPayoutMethodMultiversx | null
  userPayoutMethodPaypal: UserPayoutMethodPaypal | null
  userPayoutMethodRevolut: UserPayoutMethodRevolut | null
  active: boolean
  isCurrent: boolean
  isNext: boolean
  createdAt: Date
  updatedAt: Date
}

export interface PayoutMethod {
  id: number
  name: PayoutMethodName
  type: PayoutMethodType
  currency: PayoutCurrency
  feesFixed: number
  feesPercent: number
  active: boolean
}

export interface UserPayoutMethodMultiversx {
  id: string
  address: string
}

export interface UserPayoutMethodPaypal {
  id: string
  email: string
  paypalPayerId: string
}

export interface UserPayoutMethodRevolut {
  id: string
  revolutCounterpartyId: string
}

export enum PayoutMethodName {
  REVOLUT = 'revolut',
  PAYPAL = 'paypal',
  MULTIVERSX = 'multiversx',
}

export enum PayoutMethodType {
  REVOLUT_EUR = 'revolut_eur',
  PAYPAL_EUR = 'paypal_eur',
  MULTIVERSX_USDC = 'multiversx_usdc',
}

export enum PayoutFiatCurrency {
  EUR = 'EUR',
}

export enum PayoutCryptoCurrency {
  USDC = 'USDC',
}

export type PayoutCurrency = PayoutCryptoCurrency | PayoutFiatCurrency
